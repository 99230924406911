<template>
  <!--srcviewscompanydetailsvuehtmlStart-->
  <!-- 公司详情 -->
  <div id="defaultId1" class="uIo8QCo" name="header" ref="scrollContainer" @scroll="handleScroll">
    <content_view v-if="!Compare" id="gc95c2" ref="contentView">
      <template  slot="header">
        <div id="cdd8de" class="uVNPU6H">
          <tools id="c46180" class="u35ff8" :UpdateStatus="UpdateStatus" :config="toolsConfig" @clickMenu="clickMenu"
            ref="tools">
          </tools>
        </div>
      </template>
      <template slot="details_view">
        <div id="g30b5a" class="ud2d40">
          <!-- style="width: 43.5%" -->
          <div id="ga36b2" class="c u4f5b" style="width: 42%">
            <div style="display: flex;align-items: center;">
              <div id="g4fdd2" class="uc4421">
                <div class="uc4421_text">
                  {{ company == null ? '' : company?.base?.companyName }}
                </div>
              </div>
              <svg t="1728813835525" class="icon" style="margin-left: 1.78rem;" viewBox="0 0 1075 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg" p-id="12745" width="1.85em" height="1.85em">
                <path
                  d="M273.757233 1023.872016a111.269069 111.269069 0 0 1-65.092533-21.142915 108.632604 108.632604 0 0 1-44.000812-107.378363l36.091416-208.459925c2.815642-16.074759-2.559675-32.379888-14.282986-43.591264l-0.102387-0.127984-152.863786-147.718839a108.58141 108.58141 0 0 1-28.054037-112.44652 109.835651 109.835651 0 0 1 89.358251-74.768104l211.249971-30.306551A49.836871 49.836871 0 0 0 343.610761 250.85019l94.477602-189.646315A110.168409 110.168409 0 0 1 537.454943 0.002048c42.413813 0 80.578567 23.421026 99.340984 61.227424l94.452005 189.646315c7.218283 14.48776 21.245302 24.624073 37.576027 27.055764l211.249972 30.434535a110.040425 110.040425 0 0 1 89.486235 74.742507 108.325443 108.325443 0 0 1-28.054037 112.472116l-152.863787 147.590856a49.504113 49.504113 0 0 0-14.385373 43.719248l36.091417 208.357538a108.607007 108.607007 0 0 1-44.128796 107.378363 110.731537 110.731537 0 0 1-116.541999 8.318944l-188.955203-98.445098a49.862467 49.862467 0 0 0-46.330116 0l-188.929606 98.445098a110.321989 110.321989 0 0 1-51.705433 12.926358z"
                  :fill="company?.base?.collectIds == '-' ? '#006B9B' : '#FF6A6A'" p-id="12746"></path>
              </svg>

            </div>
            <div id="gb2142" class="u7bd96">
              <div id="g77a65" class="u0e1f6">
                <img id="gf2e43" class="uc6a2b" :src="company?.base?.img" alt="" />
                <el-tooltip :disabled="company?.base?.address.length < 60" :content="company?.base?.address"
                  placement="top">
                  <div id="g767ad" class="ubb08b">
                    {{ company?.base?.address }}
                  </div>
                </el-tooltip>

              </div>
              <span id="gbbaca" class="u5b01d" style="text-decoration: underline; cursor: pointer"
                v-for="(item, index) in company?.base?.website.split(';')" :key="index" @click="setid(item)">
                {{ item }}
               
              </span>
          
            </div>
          </div>
          <div id="g1d5a8" class="u83191">
            <div id="c66f7e" class="uboa8Fv">
              <div id="g4c146" class="u07ca9">
          
              </div>
              <div id="gd2064" :class="isShowMoreMark ? '' : 'submore'" class="u61637">
                <div id="g62e79" v-for="(item, index) in markList" class="u91cf9" :key="index">
                  {{ item.label }}
                </div>
              </div>
       
            </div>
          </div>
          <div id="gfa390" class="u6f31f">
            <div class="DQihover">
              <h3>DQI</h3>
              Data Quality Index (DQI) utilizes algorithms to measure various aspects of data such as completeness,
              validity, reliability of sources, consistency, timeliness, and bilingual support. The index is designed to
              evaluate the quality of data on a scale from 1 to 100, where a higher score indicates better data quality.
              Algorithms can analyze data based on predefined rules or criteria, allowing for objective and standardized
              measurements. Our DQI is a good indicator that enables users to have a quantitative assessment of data
              quality, facilitating data-driven decision-making and enhancing the overall value and utility of the data.
            </div>
            <div v-show="CreditScore" id="g1e8af" ref="dataQuality" class="u1c1b4"></div>
            <div id="g37e75" class="u0304c "></div>
          </div>
        </div>
        <div id="g29da1" class="ubf224">
          <div id="g06fa7" class="ue896e">
            <ul id="ga9199" class="ufb44d">
              <div id="g251bf"></div>
              <!-- item.name!='marketAnalysis'" -->
              <li id="ga557d" @click="openUrl(item, '')" v-for="(item, index) in breadcrumbBar" :key="index"
                v-show="item.isShow" :class="tab1ActiveName == item.id ? 'bread_active' : ''" class="u99b63">
                <div class="textu99b63" :class="tab1ActiveName == item.id ? 'bread_active2' : ''">
                  {{ item.screenName }}
                </div>

              </li>
            </ul>
          </div>
          <div id="g599f6" v-show="isNoShowTab2 != 'noShowAll'" class="u2ac23 mb10">
            <div id="gc2939" @mouseleave="leaveTab2" v-show="isNoShowTab2 != 'showMark'" class="udea11">
              <div id="g7e054" :style="tab2BkStyle" class="u518ac"></div>
              <div id="g608e1" @click="openUrl('', item)" @mouseenter="overTab2($event)" v-show="item.isShow"
                v-for="(item, index) in tab2_list" :key="index" :class="tab2ActiveName == item.id ? 'tab2_active' : ''"
                class="uebc34">
                {{ item.screenName }}
              </div>
            </div>
            <div id="gfff13" class="u8bb4e" v-if="markTotalList.length > 0"
              :class="[isNoShowTab2 != 'showMark' ? 'mt20' : 'mt0']">
              <div v-for="(item, index) in markTotalList" :key="index">
                <gui_mark_total id="g9eda6" class="u661e6" :label="item.name" :total="item.num" v-if="item.num != '-'">
                </gui_mark_total>
              </div>
            </div>
          </div>
          <div class="back-to-top" v-show="showBackToTop" @click="scrollToTop">
            <button class=" showBackToTop"><svg t="1732090638802" class="icon" viewBox="0 0 1024 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg" p-id="6153" width="20" height="20">
                <path
                  d="M564.4 213.7C549.9 199.2 530.9 192 512 192c-19 0-38 7.2-52.5 21.7L73.4 599.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l361.3-361V928c0 17.7 14.3 32 32 32s32-14.3 32-32V283.7l361.4 361c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L564.4 213.7zM96 129l832-1c17.7 0 32-14.4 32-32 0-17.7-14.3-32-32-32L96 65c-17.7 0-32 14.4-32 32 0 17.7 14.3 32 32 32z"
                  p-id="6154" fill="#FFFFFF"></path>
              </svg></button>
          </div>

          <div id="g1e372" class="u0c804">
            <router-view :companyBaseRoute="company" id="cb02bf" @UpdatamarkTotalList="childUpdatamarkTotalList">
            </router-view>
          </div>
        </div>
      </template>
    </content_view>
    <!-- <div id="cc35aa" @click="setThemeColor()"  >文字{{$store.state.app}}
         </div>
            设置主题
            
            设置主题色
             -->
    <Suo :suoClose="Update" @closeCloseDialog="clossuoDialog($event)" @closeCloseDialog1="clossuoDialog1($event)"
      :ComapnyName="company?.base?.companyName"></Suo>
    <div style="width: 100%;" v-if="Compare">
      <compareCompanys :compareList="compareList" @CompareShowfalse="CompareShow($event)"></compareCompanys>
    </div>
    <Reporterror :showReport="ReportError" :companynameen="companyName" :Company="company"
      @closeReportDialog="closeDialog($event)" :powerStatus="powerToReportErrorStatus"></Reporterror>

    <el-dialog :visible.sync="hasPrivilege" center :close-on-click-modal="true" @close="chasPrivilegeDialog"
      custom-class="dialog_normalsuo dialog_normalpower" :append-to-body="true">
      <div>
        <div class="detail_cont">
          <div class="detail_cont_text">
            Your permission cannot browse this content. Click the "renew" button to upgrade your permission
          </div>

        </div>
      </div>
      <div class="flex_center">
        <el-button class="btn_export btn_exportCCC" @click="hasPrivilege = false">OK</el-button>
        <el-button class="btn_export" @click="$router.push({
          path: '/index/addCredit',
        })">Subscribe</el-button>
      </div>
    </el-dialog>
    <PermissionDialog></PermissionDialog>
    <ReLogin :reLoginPop="reLoginVis"></ReLogin>

  </div>
  <!--srcviewscompanydetailsvuehtmlEnd-->
</template>
<script>
// interfaceCode
//importStart
import PermissionDialog from '@/components/power/PermissionDialog.vue';
import { mixins1 } from "@/mixins/index";
import content_view from "../layout/content_view.vue";
import gui_tabs from "../../components/gui_tabs/gui_tabs.vue";
import gui_tab_item from "../../components/gui_tabs/gui_tab_item.vue";
import gui_collapse from "../../components/gui_collapse/gui_collapse.vue";
import gui_checkbox from "../../components/gui_checkbox/gui_checkbox.vue";
import gui_scroll_x from "../../components/gui_scroll_x/gui_scroll_x.vue";
import gui_link_content from "../../components/gui_link_content/gui_link_content.vue";
import { goAnchor, GetQueryString } from "../../utils/anchor.js";
import * as echarts from "echarts/core";
import { menuRouter, tabList } from "../../config/index.js";
import { getCompanySnap } from "@/api/companyApi.js";
import gui_mark_total from "../../components/gui_mark_total/gui_mark_total.vue";
import tools from "./component/tools.vue";
import Suo from "./list/UpdateDialog.vue"
import Reporterror from "./list/detailreportError.vue";
import compareCompanys from './list/detailecompareCompanys'
import ReLogin from "../../components/ReLogin.vue";
//importEnd
import { saveUserConsumption, getOfflinePdf, downloadOnlinePdf, gettabList, whetherDeductPoints, getTabListOneLayer } from "@/api/userApi";
export default {
  name: "",
  mixins: [mixins1],
  props: {},
  watch: {
    '$route'(to, from) {
      // 在路由变化时执行的代码
      if (to.name == 'businessActivitiesimport&Export') {
        this.tab2ActiveName = to.query.idpower
      }


    }
  },
  mounted() {
    this.gettabList()
    this.setThemeColor();
  },
  created() {
    this.getCompanySnaplist();
    window.addEventListener('scroll', this.handleScroll);
    this.whetherDeductPointsDetail()

  },
  beforeUnmount() {
    // 移除页面滚动事件的监听
    window.removeEventListener('scroll', this.handleScroll);
  },
  active() {
  },

  components: {
    PermissionDialog,
    Suo,
    Reporterror,
    compareCompanys,
    tools,
    gui_mark_total,
    content_view,
    gui_tabs,
    gui_tab_item,
    gui_collapse,
    gui_checkbox,
    gui_scroll_x,
    gui_link_content,
    ReLogin,
  },

  data() {
    return {
      showBackToTop: false,
      reLoginVis: false,
      countryDQI: '',
      companyName: '',
      UpdateStatus: 0,
      Compare: false,
      ReportError: false,
      powerToReportErrorStatus: false,
      Update: false,
      Download: false,
      hasPrivilege: false,
      randomKey: this.getRandomInt(1, 100),// 
      // insertData
      // srcviewscompanydetailsvueDataStart

      // option2
      company: null,
      compareList: [],
      option2: {

      },
      // option1

      // isShowMenu
      isShowMenu: true,
      // cascader6Props
      cascader6Props: {
        expandTrigger: "click",
        multiple: true,
        checkStrictly: false,
        emitPath: true,
        lazy: false,
        lazyLoad: () => { },
        value: "value",
        label: "label",
        children: "children",
        disabled: "disabled",
        leaf: "leaf",
        hoverThreshold: 500,
      },
      // sort_listProps
      sort_listProps: {
        expandTrigger: "click",
        multiple: true,
        checkStrictly: false,
        emitPath: true,
        lazy: false,
        lazyLoad: () => { },
        value: "value",
        label: "label",
        children: "children",
        disabled: "disabled",
        leaf: "leaf",
        hoverThreshold: 500,
      },
      // toolsConfig
      toolsConfig: {
        menu: {
          isShow: true,
          isActive: false,
        },

        filter: {
          isShow: false,
          isActive: false,
        },

        select: {
          isShow: true,
          isActive: false,
        },

        edit: {
          isShow: true,
          isActive: false,
        },

        sort: {
          isShow: true,
          isActive: false,
        },

        collect: {
          isShow: true,
          isActive: false,
        },

        saved: {
          isShow: true,
          isActive: false,
        },

        compare: {
          isShow: true,
          isActive: true,
        },

        export: {
          isShow: true,
          isActive: false,
        },

        download: {
          isShow: true,
          isActive: true,
        },

        update: {
          isShow: true,
          isActive: false,
        },

        reportError: {
          isShow: true,
          isActive: true,
        },

        share: {
          isShow: true,
          isActive: true,
        },
      },
      // star
      star: false,
      // isShowMoreMark
      isShowMoreMark: false,
      // markList
      markList: [],
      // breadcrumbBar
      // breadcrumbBar: tabList,
      breadcrumbBar: [],
      // tab1ActiveName
      tab1ActiveName: "Business Activities",
      // isNoShowTab2
      isNoShowTab2: false,
      hasPrivilege: false,
      // 背景样式
      tab2BkStyle: {
        width: "",
        height: "",
        left: "",
        top: "",
        display: "block",
      },
      // tab2_list
      tab2_list: ["Snapshot", "Profile", "Ownership"],
      // tab2ActiveName
      tab2ActiveName: "Snapshot",
      // markTotalList
      markTotalList: [],
      CreditScore: "",
      // srcviewscompanydetailsvueDataEnd
    };
  },
  methods: {
    handleScroll() {
      // 判断页面滚动距离是否超过200px，更新showBackToTop的值
      const scrollTop = this.$refs.scrollContainer.scrollTop;
      // console.log(`window.pageYOffset`, window.pageYOffset);
      this.showBackToTop = scrollTop > 390;
    },
    scrollToTop() {
      const container = this.$refs.scrollContainer; // 使用 ref 获取容器
      container.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      // 使用scrollTo()方法将页面滚动到顶部
      // window.scrollTo({
      //     top: 0,
      //     behavior: 'smooth',
      // });
    },
    whetherDeductPointsDetail() {
      if (this.$route.query.id3a) {
        whetherDeductPoints({
          companyAaaid: this.$route.query.id3a
        }).then(res => {
          this.UpdateStatus = res.data.status
          if (this.UpdateStatus == 6 || this.UpdateStatus == 2) {
            this.toolsConfig.update.isActive = true
          }

        })
      }

    },
    gettabList() {
      getTabListOneLayer({
        aaaId: this.$route.query.id3a,
        moduleType:4
      }).then(res => {
        let routerName = this.$route.name;

        if (res.code == 200) {
          this.breadcrumbBar = res.data
          let moduleId = res.data.find(item => routerName.includes(item.name))?.id || null
          this.tab1ActiveName = moduleId
          if (moduleId) {
            getTabListOneLayer({
              aaaId: this.$route.query.id3a,
              moduleId: moduleId,
            }).then(res => {
              this.tab2_list = res.data
              let tab2ActiveName = res.data.find(item => routerName.includes(item.name))?.id || null
              this.tab2ActiveName = tab2ActiveName
            })
          }
          let arr = []
          res.data.map((item, index) => {
            // || item.name == 'marketAnalysis'
            if (item.name == 'creditOpinion') {
              arr.push({
                id: item.name,
                name: item.screenName,
                children: item.name == 'creditOpinion' ? [{ name: 'tab2', id: 'list', }] : [{
                  name: 'tab2',
                  id: 'list',
                  children: [
                    {
                      name: "Country",
                      id: "Country",
                      num: "US",
                    },
                    {
                      name: "Industry",
                      id: "Industry",
                      num: "Business Services",
                    },
                    {
                      name: "Size",
                      id: "Size",
                      num: "Miniature",
                    },
                    {
                      name: "Liquidity",
                      id: "Liquidity",
                      num: " Caution",
                    },
                    {
                      name: "Company Nature",
                      id: "Company Nature",
                      num: "Listed Companies",
                    },
                    {
                      name: "Number of Industry",
                      id: "Number of Industry",
                      num: "1,000 Companies",
                    },
                    {
                      name: "Industry Rating",
                      id: "Industry Rating",
                      num: "BBB-",
                    },
                    {
                      name: "Performance Comparis",
                      id: "Performance Comparis",
                      num: "Good",
                    },
                    {
                      name: "Attribute Comparison",
                      id: "Attribute Comparison",
                      num: "Good",
                    },
                    {
                      name: "Rating Comparison",
                      id: "Rating Comparison",
                      num: "Good",
                    },
                  ],
                }],
                isShow: item.isShow,
                hasPrivilege: item.hasPrivilege,
                isNoShowTab2: item.name == 'creditOpinion' ? 'noShowAll' : 'showMark',
              })
            } else {

            }
          })
        }
      })
    },
    //关闭report弹窗
    closeDialog(value) {
      this.ReportError = value;
    },
    chasPrivilegeDialog() {
      this.hasPrivilege = false
    },
    clossuoDialog(value) {
      this.Update = value;
    },
    clossuoDialog1(value) {
      // this.suopower = value;
    },
    CompareShow(val) {
      this.Compare = false

    },

    childUpdatamarkTotalList(value) {
      this.markTotalList = value
    },
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    setid(row) {
      if (row.includes("http")) {
        let routeData = row;
        window.open(routeData, "_blank");
      } else {
        let routeData = "http://" + row;
        window.open(routeData, "_blank");
      }
    },

    // insertMethod
    // srcviewscompanydetailsvueMethodStart
    // initTab
    getCompanySnaplist() {
      const id3a = this.$route.query.id3a;
      getCompanySnap({
        id3a: id3a,
      }).then((res) => {
        this.company = res.data;
        this.companyName = res.data.base.companyName
        this.countryDQI = res.data.base.countryDQI && res.data.base.countryDQI != "-" ? parseInt(res.data.base.countryDQI) : 0;
        let countryImg = this.getCountryImgUrl(res.data.base.countryFlag);
        this.company.base.img = require("../../../static" + countryImg);
        localStorage.setItem('CompanyBase', JSON.stringify(res.data))
        this.CreditScore =
          res.data.base.companyDQI != "n.a."
            ? parseInt(res.data.base.companyDQI)
            : "n.a.";
        this.renderChart();
        this.markList = res.data.labels;
      }).catch(err => {
        this.reLoginVis = String(err).includes('403')
      })
    },
    initTab() {
      // console.log('menuRouter',menuRouter);
      let routes = {};
      this.breadcrumbBar.forEach((item) => {
        item.children.forEach((item2) => {
          let obj = {
            tab1: item.id,
            tab2: item2.id,
            isNoShowTab2: item.isNoShowTab2,
            tab2List: item.children,
          };
          routes[item.id + item2.id] = obj;
        });
      });

      let routerName = this.$route.name;

      this.tab1ActiveName = routes[routerName].tab1;
      this.tab2ActiveName = routes[routerName].tab2;
      this.tab2_list = routes[routerName].tab2List;

      // console.log('initTab', routerName, this.tab1ActiveName, this.tab2ActiveName)

      let markTotal = this.tab2_list.find(
        (item) => item.id == this.tab2ActiveName
      );

      let isNoShowTab2 = routes[routerName].isNoShowTab2;

      if (isNoShowTab2) {
        this.isNoShowTab2 = isNoShowTab2;
      } else {
        this.isNoShowTab2 = false;
      }
    },

    // renderChart
    renderChart() {
      this.$nextTick(() => {
        this.initPic(this.CreditScore);
        if (this.countryDQI > 0) {
          this.initCountryDQI(this.countryDQI)
        }
      });
    },
    initPic(num) {
      const myChart = echarts.init(this.$refs.dataQuality);
      let data = num;

      let option = {
        title: {
          text: data,
          subtext: "Company DQI",
          x: "48%",
          y: "18%",
          textAlign: "center",

          textStyle: {
            fontSize: '1.85rem',
            color: "#FFFFFF",
          },

          subtextStyle: {
            fontSize: '1.1428575rem',
            color: "#FFFFFF",
          },
        },

        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["51.4%", "66.0%"],
            center: ["50%", "31%"],
            startAngle: 180,
            endAngle: 360,

            label: {
              show: false,
            },

            data: [
              {
                value: data,

                itemStyle: {
                  color: "#FF9800",
                },
              },
              {
                value: 100 - data,

                itemStyle: {
                  color: "#006D9F",
                },
              },
            ],
          },
        ],
      };
      myChart.setOption(option);
    },
    initCountryDQI(num) {
      const chartContainer2 = document.getElementById("g37e75");
      const chart2 = echarts.init(chartContainer2);
      let data = num;
      let option = {
        title: {
          text: data,
          subtext: "Country DQI",
          x: "48%",
          y: "18%",
          textAlign: "center",
          textStyle: {
            fontSize: '1.85rem',
            color: "#FFFFFF",
          },

          subtextStyle: {
            fontSize: '1.1428575rem',
            color: "#FFFFFF",
          },
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["51.4%", "66.50%"],
            center: ["50%", "32.5%"],
            startAngle: 180,
            endAngle: 360,

            label: {
              show: false,
            },
            data: [
              {
                value: data,
                itemStyle: {
                  color: "#022955",
                },
              },
              {
                value: 100 - data,
                itemStyle: {
                  color: "#006D9F",
                },
              },
            ],
          },
        ],
      };
      chart2.setOption(option);
    },
    // clickMenu 控制详情
    clickMenu(name, data) {

      switch (name) {
        case "menu":
          this.$refs.contentView.isMenu = !this.$refs.contentView.isMenu;
          break;
        case "select":
          this.$nextTick(() => {
            if (this.$refs.route.isOpenSlct) {
              this.$refs.route.isOpenSlct = false;
              this.$refs.route.isOpenSlct = false;
            } else {
              this.$refs.route.isOpenSlct = "Select";
              this.$refs.route.isOpenSlct = "Select";
            }
          });
          break;
        case "Update":
          this.Update = true

          break;
        case "download":
          this.Download = true
          downloadOnlinePdf(this.$route.query.id3a).then(res => {
            const binaryData = [];
            binaryData.push(res);
            let pdfUrl = window.URL.createObjectURL(
              new Blob(binaryData, { type: "application/pdf" })
            );
            window.open(pdfUrl, '_blank')


          }).catch(err => {
            this.reLoginVis = String(err).includes('403')
          })
          break;
        case "Compare":
          this.Compare = true
          break;

        case "Report":
          this.ReportError = true;
          this.powerToReportErrorStatus = true;
          break;


        case "edit":
          // this.$refs.route.columns = data;
          break;
      }
    },

    // toggleMoreMark
    toggleMoreMark() {
      this.isShowMoreMark = !this.isShowMoreMark;
    }, // openUrl
    openUrl(tab1Name, tab2Name) {
      let newscountry = JSON.parse(localStorage.getItem("countryList"))[0].countryList;
      let SACode1 = "";
      newscountry.map((item) => {
        if (item.countryCode == this.company.base.countryFlag) {
          SACode1 = item.dowjonesCountrycode;
        }
      });
     
      if (tab1Name?.hasPrivilege || tab2Name?.hasPrivilege) {
        if (!tab1Name) {
          let tab1NameVal = this.breadcrumbBar.find(item => item.id == this.tab1ActiveName)?.name
          let tab2NameVal = tab2Name.name
          this.tab2ActiveName = tab2Name.id
          this.$router.push({
            name: tab1NameVal + tab2NameVal,
            params: {
              id: this.$route.params.id,
              idpower: tab2Name?.id
            },
            query: {
              id3a: this.$route.query.id3a,
              companyCountry: this.company.base.countryFlag,
              SACode1: SACode1,
              idpower: tab2Name.id == undefined ? tab1Name?.id : tab2Name.id,
              companyName: this.$route.query.companyName,
            },
          });
        } else {
          this.tab1ActiveName = tab1Name?.id
          getTabListOneLayer({
            aaaId: this.$route.query.id3a,
            moduleId: tab1Name?.id,
          }).then(res => {
            if (res.data.length > 0) {
              this.tab2_list = res.data.filter(item => item.isShow == true)
              if (this.tab2_list.length > 0) {
                this.isNoShowTab2 = ""
                this.tab2ActiveName = this.tab2_list[0]?.id
                this.$router.push({
                  name: tab1Name.name + this.tab2_list[0]?.name,
                  params: {
                    id: this.$route.params.id,
                    idpower: tab2Name?.id
                  },
                  query: {
                    id3a: this.$route.query.id3a,
                    companyCountry: this.company.base.countryFlag,
                    SACode1: SACode1,
                    idpower: tab2Name.id == undefined ? tab1Name?.id : tab2Name.id,
                    companyName: this.$route.query.companyName,
                  },
                });
              }
            } else {
              if (tab1Name?.name == 'creditOpinion' || tab1Name?.name == 'marketAnalysis') {
                if (tab1Name?.name == 'creditOpinion') {
                  this.isNoShowTab2 = "noShowAll"
                } else if (tab1Name?.name == 'marketAnalysis') {
                  this.isNoShowTab2 = "showMark"
                }
                this.tab2_list = tab1Name?.name == 'creditOpinion' ? [{ name: 'tab2', id: 'list', }] :
                  [{
                    name: 'tab2',
                    id: 'list',
                    children: [
                      {
                        name: "Country",
                        id: "Country",
                        num: "US",
                      },
                      {
                        name: "Industry",
                        id: "Industry",
                        num: "Business Services",
                      },
                      {
                        name: "Size",
                        id: "Size",
                        num: "Miniature",
                      },
                      {
                        name: "Cash Flow Status",
                        id: "Cash Flow Status",
                        num: " Caution",
                      },
                      {
                        name: "Company Nature",
                        id: "Company Nature",
                        num: "Listed Companies",
                      },
                      {
                        name: "Number of Industry",
                        id: "Number of Industry",
                        num: "1,000 Companies",
                      },
                      {
                        name: "Industry Rating",
                        id: "Industry Rating",
                        num: "BBB-",
                      },
                      {
                        name: "Performance Comparis",
                        id: "Performance Comparis",
                        num: "Good",
                      },
                      {
                        name: "Attribute Comparison",
                        id: "Attribute Comparison",
                        num: "Good",
                      },
                      {
                        name: "Rating Comparison",
                        id: "Rating Comparison",
                        num: "Good",
                      },
                    ],
                  }]
                this.$router.push({
                  name: tab1Name.name + this.tab2_list[0].id,
                  params: {
                    id: this.$route.params.id,
                    idpower: tab2Name?.id
                  },
                  query: {
                    id3a: this.$route.query.id3a,
                    companyCountry: this.$route.query.companyCountry,

                    SACode1: SACode1,
                    idpower: tab2Name.id == undefined ? tab1Name?.id : tab2Name.id,
                    companyName: this.$route.query.companyName,
                  },
                });
              }
            }
          })
        }
      } else {
        this.hasPrivilege = true
      }
    }, // 离开悬浮
    leaveTab2(event) {
      this.tab2BkStyle.display = `none`;
    }, // 悬浮tab2监听
    overTab2(e) {
      let event = e;
      const rect = event.target.getBoundingClientRect();
      const parentRect = event.target.parentNode.getBoundingClientRect();
      this.tab2BkStyle.width = `${rect.width}px`;
      this.tab2BkStyle.height = `${rect.height}px`;
      this.tab2BkStyle.left = `${rect.left - parentRect.left}px`;
      this.tab2BkStyle.top = `${rect.top - parentRect.top}px`;
      this.tab2BkStyle.display = `block`;
    }, // setThemeColor
    setThemeColor() {
      this.$store.dispatch("app/setThemColor", "#1290C9");
      this.$store.dispatch("app/setSearchType", "company");
    },
    // srcviewscompanydetailsvueMethodEnd
  },
  computed: {},
};
</script>
<style>
.srcviewscompanydetailsvuecssStart {}

.uIo8QCo {
  display: flex;
  justify-content: center;
  overflow-y: auto;

}

.uVNPU6H {
  width: 100%;
  /* margin-top: 0.4em; */
}

.u35ff8 {
  width: 100%;
  overflow: hidden;
}

.ud2d40 {
  display: flex;
  min-height: 24.4rem;
  /* max-height: 290px; */
  padding-top: 5.5rem;
  padding-left: 5%;
  padding-right: 5%;
  background-color: #1290c9;

}

.u4f5b {
  display: block;
  justify-content: flex-start;
  align-items: flex-start;
  border-color: rgb(172, 201, 24);
  color: rgb(255, 255, 255);
  margin-right: 1.85rem;
}

.uc4421 {
  color: rgb(255, 255, 255);
  margin-top: 0rem;
  margin-bottom: 0rem;
  margin-left: 0rem;
  margin-right: 0rem;
  padding-top: 0rem;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-bottom: 0rem;
  /* line-height: 2.857142857142857em;
  height: 3.4285714285714284em; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.uc4421_text {
  font-size: 2.57rem;
  font-family: 'DMSans Bold';
  overflow: hidden;
  text-overflow: ellipsis;
  /* font-weight: 800; */
}

.u7bd96 {
  /* height: 1.4285714285714286em; */
}

.u0e1f6 {
  display: flex;
  align-items: center;
  /* height: 1.4285714285714286em; */
  margin-top: 1.28em;
  margin-bottom: 0.85rem;
  flex-direction: row;
}

.uc6a2b {
  width: 2.57rem;
  height: 1.57rem;
  margin-right: 0.71rem;
}

.ubb08b {
  /* height: 1.4285714285714286em; */
  color: rgb(255, 255, 255);
  font-size: 1.285714rem;
  /* height: 3.142857142857143em; */
  height: auto;
  overflow-y: auto;
  min-width: 40%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.u5b01d {
  color: rgb(255, 255, 255);
  margin-top: 1.4285714285714286rem;
  font-size: 1.285714rem;
}

.u83191 {
  display: flex;
  /* height: 2.142857142857143em; */
  flex: auto;
  width: 0;
  margin-top: 0.8571428571428571rem;
}

.uboa8Fv {
  display: flex;
  /* align-items: center; */
}

.u07ca9 {
  display: inline-block;
  /* margin-top: 0.4em; */
  margin-left: -1.4rem;
  margin-right: 1.4285714285714286rem;
  box-sizing: content-box;
}

.u61637 {
  /* height: 10.785714285714286em; */
  /* height: 4.857142857142857em; */
  border-color: rgb(53, 24, 201);
  overflow-y: auto;
}

.u91cf9 {
  display: inline-block;
  border:1px solid rgb(255, 255, 255);
  /* background-color: rgb(254, 254, 254); */
  padding:8px 12px;
  color: rgb(255, 255, 255);
  margin-right: 0.42rem;
  margin-bottom: 0.42rem;
  font-size: 1rem;
  border-radius: 6px;
}

.u0a580 {
  /* padding-top: 1em; */
}

.u9KBSet {
  transform: rotate(1deg);
}

.u6f31f {
  display: flex;
  width: 23.65rem;
  height: 8.57rem;
  position: relative;
}

.u6f31f:hover .DQihover {
  display: block;
}

.DQihover {
  display: none;
  position: absolute;
  left: 0rem;
  top: 7.5rem;
  background: #FFFFFF;
  box-shadow: 0rem 0.2857142857142857rem 1.4285714285714286rem 0rem rgba(0, 0, 0, 0.1);
  border-radius: 0.2857142857142857rem;
  font-size: 1.2857142857142858rem;
  color: #29445D;
  line-height: 2rem;
  text-align: left;
  font-style: normal;
  padding: 1.4285714285714286rem 1.4285714285714286rem 2.142857142857143rem;
  width: 115%;
  z-index: 99999;
}

.DQihover h3 {
  margin-bottom: 1.4285714285714286rem;
  font-size: 1.7142857142857142rem;
  color: #1A2332;
}

.u1c1b4 {
  width: 71.42857142857143rem;
  height: 14.285714285714286rem;
}

.u0304c {
  width: 71.42857142857143rem;
  height: 14.285714285714286rem;
}

.ubf224 {
  height: 12.857em;
  margin-top: -8.92rem;
  margin-left: 1.42rem;
  margin-right: 1.42rem;
  position: relative;
  z-index: 99;
}

.ue896e {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: -0.21428571428571427rem;
}

.ufb44d {
  display: flex;
  flex-direction: row;
  text-align: center;
}

.u99b63 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  color: rgb(255, 255, 255);
  margin-right: 1rem;
  line-height: 1.42rem;
  padding: 0.92rem 1.42rem;
  background-color: #40a6d4;
  text-align: center;
  border-top-width: 0.07142857142857142rem;
  border-top-style: none;
  border-top-color: rgb(187, 69, 69);
  border-left-style: none;
  border-left-color: rgb(234, 145, 145);
  border-right-style: none;
  border-right-color: rgb(188, 38, 38);
  border-radius: 0.28rem 0.28rem 0rem 0rem;
  box-sizing: border-box;

}

.textu99b63 {
  font-size: 1.285714rem;
}

.u99b63:last-child {
  margin-right: 0;
  /* 最后一个元素的 margin 为 0 */
}

.bread_active {

  background-color: rgb(255, 255, 255);
}

.bread_active2 {
  font-family: 'DMSans Bold';
  color: #1290c9;

}

.u2ac23 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
  border-bottom-left-radius: 0.2857142857142857rem;
  border-top-left-radius: 0.2857142857142857rem;
  border-top-right-radius: 0.2857142857142857rem;
  border-bottom-right-radius: 0.2857142857142857rem;
  padding-top: 2.142857142857143rem;
  padding-bottom: 2.142857142857143rem;
  box-shadow: 0rem 0.14285714285714285rem 1rem 0rem rgba(0, 0, 0, 0.12);
}

.udea11 {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  padding: 0.35rem 1.42rem;
  position: relative;
  border-bottom-left-radius: 0.28rem;
  border-top-left-radius: 0.28rem;
  border-top-right-radius: 0.28rem;
  border-bottom-right-radius: 0.28rem;
}

.u518ac {
  background-color: rgba(255, 255, 255, 1);
  position: absolute;
  border-top-left-radius: 0.28rem;
  border-bottom-left-radius: 0.28rem;
  border-top-right-radius: 0.28rem;
  transition: 0.4s;
  z-index: 0;
  pointer-events: none;
}

.uebc34 {
  margin-right: 1.4285714285714286rem;
  padding: 0.7142857142857143rem 1.4285714285714286rem;
  font-size: 1.285714rem;
  border-bottom-left-radius: 0.2857142857142857rem;
  border-top-left-radius: 0.2857142857142857rem;
  border-top-right-radius: 0.2857142857142857rem;
  border-bottom-right-radius: 0.2857142857142857rem;
  z-index: 5;
}

.uebc34:last-child {
  margin-right: 0;
  /* 最后一个元素的 margin 为 0 */
}

.tab2_active {
  background-color: rgb(255, 255, 255);
  z-index: 10;
  font-weight: 400;
  font-family: 'DMSans Bold';
  /* font-family: "Arial Bold"; */
}

.u8bb4e {
  display: flex;
}

.u661e6 {
  margin-right: 7.5px;
  margin-left: 7.5px;
  height: 100%;
}

.u0c804 {
  border-radius: 0.2857142857142857rem;
}

.uboa8Fv .submore {
  height: 6rem;
  overflow-y: auto;
  margin-right: 2.142857142857143rem;
}

/* .submore::-webkit-scrollbar {
  background-color: #ffffff;
}

.submore::-webkit-scrollbar-corner {
  background-color: red;
} */

.submore::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, .2);
}

.u91cf9:nth-child(5n + 5) {
  color: #f8f8f6;
  /* background-color: #81c97a; */
  border: 1px solid #81c97a;
}

.u91cf9:nth-child(5n + 4) {
  color: #ffffff;
  /* background-color: #f867b5; */
  border: 1px solid #f867b5;
}

.u91cf9:nth-child(5n + 3) {
  color: #fefefe;
  /* background-color: #8e86dd; */
  border: 1px solid #8e86dd;
}

.u91cf9:nth-child(5n + 2) {
  color: #f8f8f6;
  /* background-color: #ff6a6a; */
  border: 1px solid #ff6a6a;
}

.u0a580 .subsvg {
  transform: rotate(180deg);
}

.back-to-top {
  z-index: 999;
  position: fixed;
  transition: opacity .4s ease-in-out 0s;
  opacity: 1;
  box-sizing: border-box;
  bottom: 10.714285714285714rem;
  right: 1.7857142857142858rem;
  cursor: pointer;
}

.showBackToTop {
  width: 2.857142857142857rem;
  height: 2.857142857142857rem;
  background: #1290C9;
  border-radius: 50%;
  box-shadow: 0rem 0.14285714285714285rem 0.7142857142857143rem 0rem rgba(0, 122, 177, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1441px) {

  .ubb08b,
  .u5b01d,
  .textu99b63,
  .uebc34 {
    font-size: 1.14285714rem;
  }

}

.srcviewscompanydetailsvuecssEnd {}
</style>